import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ProjectCard = ({ project }) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Link
      className="no-underline border border-gray-300 shadow-lg cursor-pointer inline-block rounded-lg hover:shadow-xl transition overflow-hidden mb-8"
      to={`/project/${project.id}`}
      data-aos="fade-up"
    >
      <div>
        <img
          src={project.image_url}
          alt="Project"
          width={380}
          height={250}
          className="mb-2"
        />
        <div className="px-4 py-1 pb-3">
          <h3 className="font-bold text-lg mb-3">{project[language].name}</h3>
          <p className="text-sm mb-1">
            <span className="text-gray-500">Client:</span>{' '}
            <span className="ml-2">{project[language].client}</span>
          </p>
          <p className="text-sm">
            <span className="text-gray-500">Location:</span>{' '}
            <span className="ml-2">{project[language].country}</span>
          </p>
        </div>
      </div>
    </Link>
  );
};
