import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ServiceCard } from '../../../components/service-card';
import { servicesList } from '../../../data/service/services-list';
import { PrimaryLink } from '../../../components/primary-link';
import { Container } from '../../../layouts';
import { SectionTitle } from '../../../components/section-title';

export const Services = () => {
  const { t } = useTranslation();

  return (
    <section className="py-14">
      <Container>
        <SectionTitle>{t('service.title')}</SectionTitle>

        <Grid
          container
          spacing={4}
          columns={{ lg: 12, md: 12, sm: 12 }}
          data-aos="fade-up"
        >
          {servicesList.slice(0, 4).map((item, index) => (
            <ServiceCard data={item} index={index} key={item.id} />
          ))}
        </Grid>

        <PrimaryLink className="mx-auto mt-10" to="/service">
          {t('common.button.more')}
        </PrimaryLink>
      </Container>
    </section>
  );
};
