import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const links = [
  {
    path: 'course',
    title: 'sidebar.courses',
  },
  {
    path: 'service',
    title: 'sidebar.services',
  },
  {
    path: 'project',
    title: 'sidebar.projects',
  },
  {
    path: 'about',
    title: 'sidebar.aboutUs',
  },
];

export const Nav = () => {
  const { t } = useTranslation();

  return (
    <ul className="navigation-links-wrapper lg:flex hidden items-center">
      {links.map((link, i) => (
        <li className="ml-8" key={i}>
          <NavLink
            to={'/' + link.path}
            className={`text-gray-800 inline-block font-semibold`}
            data-text={t(link.title)}
          >
            {t(link.title)}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
