import { Link } from 'react-router-dom';

export const PrimaryLink = ({ children, to = '/', className }) => {
  return (
    <Link
      to={to}
      className={`max-w-[120px] block px-5 py-2.5 rounded-lg text-white bg-base-color font-semibold hover:opacity-90 text-lg cursor-pointer relative break-words whitespace-nowrap no-underline text-center ${className}`}
      data-aos="fade-up"
    >
      {children}
    </Link>
  );
};
