import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowRight } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemButton,
  Menu,
  MenuItem,
} from '@mui/material';

export const LangMenu = ({ type = 'menu' }) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [lang, setLang] = React.useState(localStorage.getItem('lang') || 'en');

  React.useEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  const handleChangeLng = (event) => {
    i18n.changeLanguage(event.target.lang);
    localStorage.setItem('lang', event.target.lang);
    setLang(event.target.lang);
    menuToggle();
  };

  let anchorEl = document.getElementById('language-btn');

  const menuToggle = () => {
    setOpen(!open);
  };

  const langIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-1"
    >
      <path
        d="M12 2.25C6.624 2.25 2.25 6.624 2.25 12C2.25 17.376 6.624 21.75 12 21.75C17.376 21.75 21.75 17.376 21.75 12C21.75 6.624 17.376 2.25 12 2.25ZM12 3.75C12.2535 3.75 12.5025 3.76559 12.75 3.78809V4.5H14.25V4.06934C16.0935 4.59359 17.6745 5.74073 18.75 7.27148V9.75L19.5 11.25V12H20.25C20.25 12.2745 20.2352 12.5467 20.209 12.8145C20.2022 12.8865 20.1872 12.9556 20.1782 13.0269C20.1535 13.2241 20.1279 13.42 20.0889 13.6128C20.0746 13.6848 20.0556 13.7554 20.0391 13.8267C19.9956 14.0172 19.9488 14.2068 19.8926 14.3921C19.8731 14.4558 19.8506 14.5181 19.8296 14.5811C19.7666 14.7716 19.6995 14.9605 19.623 15.145C19.6005 15.199 19.576 15.2514 19.5527 15.3047C19.4687 15.4959 19.3807 15.6842 19.2832 15.8672C19.26 15.9114 19.2347 15.9548 19.21 15.9976C19.105 16.1866 18.9938 16.3698 18.8745 16.5498C18.8513 16.5851 18.8267 16.6215 18.8027 16.6567C18.6767 16.8405 18.5431 17.0189 18.4028 17.1914C18.3796 17.2199 18.3587 17.2472 18.3354 17.2749C18.1899 17.4497 18.036 17.6168 17.877 17.7788C17.8537 17.8021 17.8314 17.8273 17.8081 17.8506C17.6454 18.0118 17.4753 18.1665 17.2998 18.3135C17.2751 18.3345 17.2535 18.3562 17.228 18.3765C17.0525 18.5205 16.8684 18.6547 16.6816 18.7837C16.6524 18.8039 16.6259 18.8272 16.5967 18.8467C16.4174 18.9674 16.2305 19.0771 16.0415 19.1836C15.161 19.6778 14.1822 20.0181 13.1382 20.1606L16.147 17.8931L16.9717 17.0684L18 15V14.25L16.5 13.5H15.75L14.25 12H12.75L12 12.75V13.5L11.25 14.25V15.75L12.75 16.9497L11.2676 20.2134C7.06083 19.8399 3.75 16.3013 3.75 12C3.75 9.444 4.91925 7.15611 6.75 5.64111V6L6 6.75V8.39941L6.75 11.25L10.5 12.75H11.25V12L9 11.25V9.75H11.25L12 7.875L13.5 6.75V6L11.25 4.5L10.1719 3.96094C10.7606 3.82744 11.3708 3.75 12 3.75ZM11.25 10.5V11.25H12.75V10.5H11.25Z"
        fill="text-gray-400"
        fillOpacity="0.6"
      ></path>
    </svg>
  );

  const menu = (
    <>
      <button
        id="language-btn"
        onClick={menuToggle}
        className="px-2 py-1.5 rounded-[12px] border border-gray-400 text-gray-700 font-bold flex items-end hover:opacity-80"
        variant="contained"
      >
        {langIcon}
        {t(`common.language.short.${lang}`)}
      </button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        disableScrollLock={true}
        onClose={menuToggle}
        className="w-[100px]"
      >
        <MenuItem
          onClick={handleChangeLng}
          lang="en"
          className={
            lang === 'en'
              ? 'language-menu-item font-bold active-link'
              : 'language-menu-item font-bold'
          }
        >
          En
        </MenuItem>
        <MenuItem
          onClick={handleChangeLng}
          lang="ru"
          className={
            lang === 'ru'
              ? 'language-menu-item font-bold active-link'
              : 'language-menu-item font-bold'
          }
        >
          Py
        </MenuItem>
        <MenuItem
          onClick={handleChangeLng}
          lang="uz"
          className={
            lang === 'uz'
              ? 'language-menu-item font-bold active-link'
              : 'language-menu-item font-bold'
          }
        >
          O'z
        </MenuItem>
      </Menu>
    </>
  );

  const accordion = (
    <Accordion
      expanded={open}
      onChange={() => setOpen((prev) => (prev = !prev))}
      sx={{
        boxShadow: 'none',
      }}
      className="language-accordion"
    >
      <AccordionSummary
        aria-controls="language-content"
        id="language-header"
        sx={[
          {
            paddingLeft: '0',
            paddingRight: '0',
            margin: '0',
            minHeight: '40px',
          },
          {
            '&.Mui-expanded': { minHeight: '40px' },
            '& .MuiAccordionSummary-content': { margin: '0' },
            '& .MuiAccordionSummary-content.Mui-expanded': { margin: '0' },
          },
        ]}
      >
        <div className="language-accordion-title">
          <KeyboardArrowRight
            className={open ? 'accordion-icon expanded' : 'accordion-icon'}
          />
          {t('common.language.title')}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleChangeLng}
              lang="uz"
              className={
                lang === 'uz'
                  ? 'language-accordion-item active-link'
                  : 'language-accordion-item'
              }
            >
              {t('common.language.long.uz')}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleChangeLng}
              lang="ru"
              className={
                lang === 'ru'
                  ? 'language-accordion-item active-link'
                  : 'language-accordion-item'
              }
            >
              {t('common.language.long.ru')}
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleChangeLng}
              lang="en"
              className={
                lang === 'en'
                  ? 'language-accordion-item active-link'
                  : 'language-accordion-item'
              }
            >
              {t('common.language.long.en')}
            </ListItemButton>
          </ListItem>
        </List>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <div className="language-wrapper">{type === 'menu' ? menu : accordion}</div>
  );
};

export default LangMenu;
