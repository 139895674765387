import { useTranslation } from 'react-i18next';

import { Container } from '../../../layouts';
import { projectsList } from '../../../data/projects';
import { ProjectCard } from '../../../components/project-card';
import { PrimaryLink } from '../../../components/primary-link';
import { SectionTitle } from '../../../components/section-title';

export const Projects = () => {
  const { t } = useTranslation();

  return (
    <section className="py-12">
      <Container>
        <SectionTitle>{t('project.title')}</SectionTitle>

        <div className="flex justify-between flex-wrap items-stretch">
          {projectsList.slice(0, 3).map((project, i) => (
            <ProjectCard key={project.id} project={project} index={i} />
          ))}
        </div>

        <PrimaryLink className="mx-auto mt-10" to="/project">
          {t('common.button.more')}
        </PrimaryLink>
      </Container>
    </section>
  );
};
