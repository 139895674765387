import React, { Fragment } from 'react';
import Slider from 'react-slick';
import { FadeUpText } from 'components/ui/CustomText';

import sliderImage1 from 'assets/images/slider-images/image1.webp';
import sliderImage2 from 'assets/images/slider-images/image2.webp';
import sliderImage3 from 'assets/images/slider-images/image3.webp';
import sliderImage4 from 'assets/images/slider-images/image4.webp';
import { Fab } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const BaseSlider = () => {
  const { t } = useTranslation();

  const slickSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 8000,
    pauseOnHover: false,
    pauseOnFocus: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const sliderItems = [
    {
      id: 1,
      image: sliderImage1,
      title: t('baseSlider.slider1.title'),
      subtitle: '',
      titleUppercase: true,
      maxWidth: 500,
    },
    {
      id: 2,
      image: sliderImage2,
      title: t('baseSlider.slider2.title'),
      subtitle: t('baseSlider.slider2.subTitle'),
      titleUppercase: true,
      maxWidth: 840,
    },
    {
      id: 3,
      image: sliderImage3,
      title: t('baseSlider.slider3.title'),
      subtitle: t('baseSlider.slider3.subTitle'),
      titleUppercase: true,
      maxWidth: 700,
    },
    {
      id: 4,
      image: sliderImage4,
      title: t('baseSlider.slider4.title'),
      subtitle: t('baseSlider.slider4.subTitle'),
      titleUppercase: false,
      maxWidth: 700,
    },
  ];

  const getSliderItem = (data) => {
    let itemBgStyle = {
      backgroundImage: data.image
        ? `url(${data.image})`
        : 'linear-gradient(to bottom right, #9f041bb5, #f5515f4d)',
    };

    return (
      <div className="slider-item2" style={itemBgStyle}>
        <FadeUpText
          delay={0.3}
          once={false}
          className={`slider-title ${data.titleUppercase ? ' uppercase' : ''}`}
        >
          <span style={{ maxWidth: data.maxWidth }}>{data.title}</span>
        </FadeUpText>
        <FadeUpText delay={0.5} once={false} className="slider-subtitle">
          {data.subtitle}
        </FadeUpText>
      </div>
    );
  };

  return (
    <Slider {...slickSettings}>
      {sliderItems.map((item) => (
        <Fragment key={`slider-${item.id}`}>{getSliderItem(item)}</Fragment>
      ))}
    </Slider>
  );
};

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <Fab onClick={onClick} style={{ ...style }}>
        <KeyboardArrowRight />
      </Fab>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <Fab onClick={onClick} style={{ ...style }}>
        <KeyboardArrowLeft />
      </Fab>
    </div>
  );
}

export default BaseSlider;
