export const servicesList = [
  {
    id: 1,
    service_img: 'https://telegra.ph/file/a77ca35dff709b3ff3deb.jpg',
    en: {
      name: 'Consulting',
      description: 'Strategic Energy Consulting Solutions',
    },
    ru: {
      name: 'Консалтинг',
      description: 'Решения для стратегического энергетического консалтинга',
    },
    uz: {
      name: 'Konsalting',
      description: 'Strategik energiya konsalting yechimlari',
    },
  },
  {
    id: 2,
    service_img: 'https://telegra.ph/file/b0cd2d8093474095860db.png',
    en: {
      name: 'Insulation Coordination',
      description:
        'Switching Over voltages, Lightning Overvoltages, Ferro Resonance.',
    },
    ru: {
      name: 'Координация изоляции',
      description:
        'Переключение напряжения, грозовые перенапряжения, феррорезонанс.',
    },
    uz: {
      name: 'Izolyatsiyani muvofiqlashtirish',
      description:
        "O'tish kuchlanishlari, chaqmoqning haddan tashqari kuchlanishlari, temir-rezonans.",
    },
  },
  {
    id: 3,
    service_img: 'https://telegra.ph/file/a77ca35dff709b3ff3deb.jpg',
    en: {
      name: 'Grid Impact Studies',
      description: 'Comprehensive Grid Impact Studies for Power Systems',
    },
    ru: {
      name: 'Исследования влияния сети',
      description:
        'Комплексные исследования воздействия энергосистем на энергосистемы',
    },
    uz: {
      name: "Grid ta'sirini o'rganish",
      description:
        "Energiya tizimlari uchun tarmoq ta'sirini kompleks o'rganish",
    },
  },
  {
    id: 4,
    service_img: 'https://telegra.ph/file/b8bd815db73cf1ed46a81.jpg',
    en: {
      name: 'Dynamic Modeling',
      description: 'Precision Dynamic Modeling for Robust Power Systems',
    },
    ru: {
      name: 'Динамическое моделирование',
      description:
        'Прецизионное динамическое моделирование для надежных энергетических систем',
    },
    uz: {
      name: 'Dinamik modellashtirish',
      description: 'Kuchli quvvat tizimlari uchun aniq dinamik modellashtirish',
    },
  },
  {
    id: 5,
    service_img: 'https://telegra.ph/file/0587b44901cf8c142f673.jpg',
    en: {
      name: 'Harmonic Analysis',
      description: 'Advanced Harmonic Analysis for Optimal Power Quality',
    },
    ru: {
      name: 'Гармонический анализ',
      description:
        'Расширенный гармонический анализ для оптимального качества электроэнергии',
    },
    uz: {
      name: 'Garmonik tahlil',
      description: "Optimal quvvat sifati uchun ilg'or garmonik tahlil",
    },
  },
  {
    id: 6,
    service_img: 'https://telegra.ph/file/a77ca35dff709b3ff3deb.jpg',
    en: {
      name: 'Transfer Capacity Analysis',
      description: 'Optimizing Power Grids with Transfer Capacity Analysis',
    },
    ru: {
      name: 'Анализ пропускной способности',
      description:
        'Оптимизация электросетей с помощью анализа пропускной способности',
    },
    uz: {
      name: 'Transfer imkoniyatlarini tahlil qilish',
      description:
        'Elektr tarmoqlarini uzatish imkoniyatlarini tahlil qilish bilan optimallashtirish',
    },
  },
  {
    id: 7,
    service_img: 'https://telegra.ph/file/c27ab808a3ed85296231d.jpg',
    en: {
      name: 'Arc Flash Studies',
      description: 'Enhancing Safety: Arc Flash Studies Expertise',
    },
    ru: {
      name: 'Исследования вспышки дуги',
      description: 'Повышение безопасности: опыт исследований вспышки дуги',
    },
    uz: {
      name: 'Arc Flash tadqiqotlari',
      description: 'Xavfsizlikni oshirish: Arc Flash Studies Expertise',
    },
  },
  {
    id: 8,
    service_img: 'https://telegra.ph/file/a77ca35dff709b3ff3deb.jpg',
    en: {
      name: 'G99 Studies',
      description: 'G99 Compliance: Specialized Power System Studies',
    },
    ru: {
      name: 'G99 Исследования',
      description:
        'Соответствие G99: специализированные исследования энергосистем',
    },
    uz: {
      name: 'G99 tadqiqotlari',
      description: "G99 muvofiqligi: Ixtisoslashgan quvvat tizimini o'rganish",
    },
  },
  {
    id: 9,
    service_img: 'https://telegra.ph/file/ede094b3726cc453a81a3.jpg',
    en: {
      name: 'Relay Protection Coordination Studies',
      description: 'Ensuring Grid Reliability: Relay Coordination Studies',
    },
    ru: {
      name: 'Координационные исследования релейной защиты',
      description: 'Обеспечение надежности сети: исследования координации реле',
    },
    uz: {
      name: "Rele himoyasini muvofiqlashtirish bo'yicha tadqiqotlar",
      description:
        "Grid ishonchliligini ta'minlash: rele muvofiqlashtirish tadqiqotlari",
    },
  },
  {
    id: 10,
    service_img: 'https://telegra.ph/file/bc6bc08a015fff2e2d6a8.jpg',
    en: {
      name: 'DigSilent scripting',
      description: 'Ensuring Grid Reliability: Relay Coordination Studies',
    },
    ru: {
      name: 'Координационные исследования релейной защиты',
      description: 'Обеспечение надежности сети: исследования координации реле',
    },
    uz: {
      name: "Rele himoyasini muvofiqlashtirish bo'yicha tadqiqotlar",
      description:
        "Grid ishonchliligini ta'minlash: rele muvofiqlashtirish tadqiqotlari",
    },
  },
  {
    id: 11,
    service_img: 'https://telegra.ph/file/58e4af3b9c50f0f507bd1.png',
    en: {
      name: 'RMS Simulation',
      description: 'Ensuring Grid Reliability: Relay Coordination Studies',
    },
    ru: {
      name: 'Координационные исследования релейной защиты',
      description: 'Обеспечение надежности сети: исследования координации реле',
    },
    uz: {
      name: "Rele himoyasini muvofiqlashtirish bo'yicha tadqiqotlar",
      description:
        "Grid ishonchliligini ta'minlash: rele muvofiqlashtirish tadqiqotlari",
    },
  },
];
