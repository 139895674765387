import { useTranslation } from 'react-i18next';
import { Container } from '../../layouts';
import { SectionTitle } from '../section-title';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export const Contact = () => {
  const { t } = useTranslation();

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    phone: Yup.string(),
    message: Yup.string()
      .required('Message is required')
      .min(50, 'Message must be at least 50 characters long'),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await fetch('https://formspree.io/f/xzbnebgj', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        console.log('Form submitted successfully!');
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('An error occurred during form submission:', error);
    }

    // Reset the form after submission
    resetForm();
  };

  return (
    <section className="py-14">
      <Container>
        <SectionTitle>{t('contact_us.title')}</SectionTitle>

        <p
          className="mt-5 text-lg text-line-[10] sm:text-line-[15] md:block text-center mb-10 mx-auto max-w-[500px]"
          data-aos="fade-up"
        >
          {t('contact_us.description')}
        </p>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="mb-5 md:flex" data-aos="fade-up">
              <div className="w-full md:w-[50%] md:mr-3 mb-5 md:mb-0">
                <Field
                  type="text"
                  name="firstName"
                  className="w-full border py-2 px-3 outline-none rounded-md border-gray-300 hover:border-gray-400 transition mb-2"
                  placeholder={t('contact_us.first_name')}
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="w-full md:w-[50%] md:ml-3">
                <Field
                  type="text"
                  name="lastName"
                  className="w-full border py-2 px-3 outline-none rounded-md border-gray-300 hover:border-gray-400 transition mb-2"
                  placeholder={t('contact_us.last_name')}
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>

            <div className="mb-5 md:flex" data-aos="fade-up">
              <div className="w-full md:w-[50%] md:mr-3 mb-5 md:mb-0">
                <Field
                  type="text"
                  name="email"
                  className=" py-2 px-3 border w-full outline-none rounded-md border-gray-300 mb-2 hover:border-gray-400 transition "
                  placeholder={t('contact_us.email')}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500"
                />
              </div>

              <div className="w-full md:w-[50%] md:ml-3">
                <Field
                  type="text"
                  name="phone"
                  className=" py-2 px-3 border w-full outline-none rounded-md border-gray-300 mb-2 hover:border-gray-400 transition"
                  placeholder={t('contact_us.phone')}
                />

                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>

            <Field
              as="textarea"
              name="message"
              cols="30"
              rows="10"
              className="w-full border py-2 px-3 mb-5 rounded-md outline-none border-gray-300 hover:border-gray-400 resize-none transition"
              data-aos="fade-up"
              placeholder={t('contact_us.message')}
            />
            <ErrorMessage
              name="message"
              component="div"
              className="text-red-500"
            />

            <button
              type="submit"
              className="max-w-[120px] block px-5 py-2.5 rounded-lg text-white bg-base-color font-semibold hover:opacity-90 text-lg cursor-pointer relative break-words whitespace-nowrap no-underline text-center mx-auto"
              data-aos="fade-up"
            >
              {t('contact_us.submit')}
            </button>
          </Form>
        </Formik>
      </Container>
    </section>
  );
};
