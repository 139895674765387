export const projectsList = [
  {
    id: 1,
    image_url: 'https://telegra.ph/file/0087c499c383d24f97623.jpg',
    en: {
      name: 'Orchard Bank Battery Storage Site',
      country: 'Forfar, Scotland',
      client: 'Orchard Bank Storage Ltd',
    },
    ru: {
      name: 'Orchard Bank Battery Storage Site',
      country: 'Форфар, Шотландия',
      client: 'Орчард Банк Стейдж Лтд.',
    },
    uz: {
      name: 'Orchard Bank akkumulyatorlarini saqlash joyi',
      country: 'Forfar, Shotlandiya',
      client: 'Orchard Bank Storage Ltd',
    },
  },
  {
    id: 2,
    image_url: 'https://telegra.ph/file/32d4459a0e1f0293b4298.jpg',
    en: {
      name: 'Braco BESS',
      country: 'Forfar, Scotland',
      client: 'Orchard Bank Storage Ltd',
    },
    ru: {
      name: 'Braco BESS',
      country: 'Форфар, Шотландия',
      client: 'Орчард Банк Стейдж Лтд',
    },
    uz: {
      name: 'Braco BESS',
      country: 'Forfar, Shotlandiya',
      client: 'Orchard Bank Storage Ltd',
    },
  },
  {
    id: 3,
    image_url: 'https://telegra.ph/file/32aeebeb914a525c9fd38.jpg',
    en: {
      name: 'ERCOT Solar PV Project 1',
      country: 'Texas, USA',
      client: 'Sunny Solar LLC',
    },
    ru: {
      name: 'ERCOT Solar PV Project 1',
      country: 'Texac, США',
      client: 'Sunny Solar LLC',
    },
    uz: {
      name: 'ERCOT Solar PV Project 1',
      country: 'Texas, AQSh',
      client: 'Sunny Solar LLC',
    },
  },
  {
    id: 4,
    image_url: 'https://telegra.ph/file/d0493332fca470a329825.jpg',
    en: {
      name: 'ERCOT Solar PV Project 2',
      country: 'Texas, USA',
      client: 'Sunshine Generators Inc',
    },
    ru: {
      name: 'ERCOT Solar PV Project 2',
      country: 'Texac, США',
      client: 'Sunshine Generators Inc',
    },
    uz: {
      name: 'ERCOT Solar PV Project 2',
      country: 'Texas, AQSh',
      client: 'Sunshine Generators Inc',
    },
  },
];
