import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import Home from 'pages/home/Home';
import AboutUs from 'pages/about/AboutUs';
import Courses from 'pages/course/Courses';
import Projects from 'pages/project/Projects';
import Services from 'pages/service/Services';

import NotFoundPage from 'components/ui/NotFoundPage';

import BaseLayout from 'layouts/BaseLayout';
import DefaultLayout from 'layouts/DefaultLayout';

import CourseItem from 'pages/course/CourseItem';
import SmoothScroll from 'components/ScrollSmooth';
import ProjectItem from 'pages/project/ProjectItem';
import ServiceItem from 'pages/service/ServiceItem';
import { DataQueriesProvider } from '../context/DataQueriesProvider';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<DefaultLayout />}>
      <Route element={<DataQueriesProvider />}>
        <Route element={<SmoothScroll />}>
          <Route element={<BaseLayout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<AboutUs />} />

            <Route path="project" element={<DefaultLayout />}>
              <Route index element={<Projects />} />
              <Route path=":projectPath" element={<ProjectItem />} />
            </Route>

            <Route path="service" element={<DefaultLayout />}>
              <Route index element={<Services />} />
              <Route path=":servicePath" element={<ServiceItem />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>

            <Route path="course" element={<DefaultLayout />}>
              <Route index element={<Courses />} />
              <Route path=":coursePath" element={<CourseItem />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>

            <Route path="*" element={<NotFoundPage />} />
            <Route path="/not-found" element={<NotFoundPage />} />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

export default router;
