import { Outlet } from 'react-router-dom';
import Footer from 'components/Footer';

import ScrollTop from 'components/ui/ScrollTop';
import { KeyboardArrowUp } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { Header } from '../components/header';

const BaseLayout = () => {
  return (
    <>
      <Header />
      <span id="back-to-top-anchor" className="absolute"></span>

      <main className="grow">
        <Outlet />
      </main>

      <footer className="footer-container">
        <Footer />
      </footer>

      <ScrollTop>
        <Fab
          aria-label="scroll back to top"
          className="!w-[50px] !h-[50px] bottom-8 !bg-base-color !text-white !bg-opacity-30 hover:!bg-opacity-100"
        >
          <KeyboardArrowUp className="!text-3xl" />
        </Fab>
      </ScrollTop>
    </>
  );
};

export default BaseLayout;
