import image404 from "assets/images/errors/404.svg"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const NotFoundPage = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return (
		<div className="not-found-page flex items-center justify-center flex-col">
			<img
				src={image404}
				alt="Page Not Found"
				className="lg:w-1/2 sm:w-2/3 w-full sm:px-10 px-5"
				loading="lazy"
			/>
			<div
				className="-mt-4 text-3xl cursor-pointer text-white bg-gradient-to-br from-base-color to-base-color-light hover:from-base-color-light hover:to-base-color font-medium px-2 py-1 rounded animate-[bounce_2s_infinite]"
				onClick={() => navigate("/")}
			>
				{t("common.errorPage.notFound.goHome")}
			</div>
		</div>
	)
}

export default NotFoundPage
