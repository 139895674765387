import { DataQueriesContext } from './index';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useQueries } from 'react-query';
import axios from 'api/axios';

export const DataQueriesProvider = () => {
  const [hasError, setHasError] = useState(false);

  const dataQueries = useQueries([
    {
      queryKey: 'services',
      queryFn: async function () {
        const response = await axios.get('/service');
        return response.data.items;
      },
      enabled: !hasError,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
    {
      queryKey: 'courses',
      queryFn: async function () {
        const response = await axios.get('/course');
        return response.data.items;
      },
      enabled: !hasError,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
    {
      queryKey: 'projects',
      queryFn: async function () {
        const response = await axios.get('project');
        return response.data.items;
      },
      enabled: !hasError,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
    {
      queryKey: 'news',
      queryFn: async function () {
        const response = await axios.get('/news');
        return response.data.items;
      },
      enabled: !hasError,
      onError: (error) => {
        setHasError(true);
      },
      retry: false,
    },
  ]);

  return (
    <DataQueriesContext.Provider
      value={{
        serviceQuery: dataQueries[0],
        courseQuery: dataQueries[1],
        projectQuery: dataQueries[2],
        newsQuery: dataQueries[3],
      }}
    >
      <Outlet />
    </DataQueriesContext.Provider>
  );
};
