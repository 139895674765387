import React from 'react';

const searchIcon = (
  <svg
    style={{ width: '24px' }}
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
    ></path>
  </svg>
);

const filterIcon = (
  <svg
    style={{ width: '24px' }}
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
    ></path>
  </svg>
);

const closeIcon = (
  <svg
    style={{ width: '20px' }}
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M6 18L18 6M6 6l12 12"
    ></path>
  </svg>
);

export const FilterButtons = ({ setSearchTextText, setOpenDrawer }) => {
  const [showInput, setShowInput] = React.useState(false);

  const openFilter = () => setOpenDrawer(true);
  const onShowInput = () => {
    setShowInput((prev) => !prev);
  };

  const handleChange = (e) => {
    setSearchTextText(e.target.value.toLowerCase());
  };

  return (
    <div className="flex justify-end mb-6" data-aos="fade-up">
      {showInput ? (
        <input
          type="search"
          placeholder="Search"
          onChange={handleChange}
          className="border w-full border-gray-400 px-2 rounded outline-none max-w-[400px] hover:border-gray-500"
        />
      ) : null}

      <button
        onClick={onShowInput}
        className="border border-gray-400 text-gray-400 p-1 md:p-2 ml-4 rounded hover:border-base-color hover:text-base-color transition"
      >
        {showInput ? closeIcon : searchIcon}
      </button>
      <button
        className="border border-gray-400 text-gray-400 p-1 md:p-2 rounded ml-4 hover:border-base-color hover:text-base-color transition"
        onClick={openFilter}
      >
        {filterIcon}
      </button>
    </div>
  );
};
