export const SectionTitle = ({ children }) => {
  return (
    <h2
      className="xl:text-4xl sm:text-[33px] text-3xl text-gray-800 font-medium mb-8 text-center"
      data-aos="fade-up"
    >
      {children}
    </h2>
  );
};
