import React from 'react';
import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import {
  BoltOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LightbulbOutlined,
  RocketLaunchOutlined,
} from '@mui/icons-material';

import { fadeUp } from 'utils/motion';
import aboutImg from '../../assets/images/about.jpg';
import { Contact } from '../../components/contact';

const AboutUs = () => {
  const { t } = useTranslation();
  const [showThirdCard, setShowThirdCard] = React.useState(false);
  const [showFirstCard, setShowFirstCard] = React.useState(false);
  const [showSecondCard, setShowSecondCard] = React.useState(false);

  return (
    <>
      <Helmet>
        <title>About us</title>
        <meta property="og:title" content="About us" />
        <link rel="canonical" href="https://mirpower.uz/about" />
        <meta name="description" content="Mirpower - About us" />
        <meta property="og:description" content="Mirpower - About us" />
      </Helmet>

      <img className="block mx-auto" src={aboutImg} alt="About" />

      <div className="container mx-auto px-4 max-w-[1280px]" data-aos="fade-up">
        <ul className="flex justify-between mt-10 flex-col lg:flex-row items-center">
          <li className="shadow-md p-5 rounded max-w-[300px] w-full text-center border mb-5 lg:mb-0 h-[138px]">
            <span className="text-4xl text-base-color mb-2 inline-block">
              10+
            </span>
            <br />
            <span className="text-xl">{t('aboutUs.smallCard.card1')}</span>
          </li>
          <li className="shadow-md p-5 rounded max-w-[300px] w-full text-center border mb-5 lg:mb-0 h-[138px]">
            <span className="text-4xl text-base-color mb-2 inline-block">
              100+
            </span>
            <br />
            <span className="text-xl">{t('aboutUs.smallCard.card2')}</span>
          </li>
          <li className="shadow-md p-5 rounded max-w-[300px] w-full text-center border mb-5 lg:mb-0 h-[138px]">
            <span className="text-4xl text-base-color mb-2 inline-block">
              $100 {t('aboutUs.smallCard.card3Amount')}+
            </span>
            <br />
            <span className="text-xl">{t('aboutUs.smallCard.card3')}</span>
          </li>
        </ul>
      </div>

      <div className="about-us-page-wrapper mt-6">
        <h2 className="text-4xl text-center" data-aos="fade-up">
          {t('aboutUs.sectionTitle')}
        </h2>

        <div className="container mx-auto px-4 max-w-[1280px]">
          <p className="about-us-page-content-item" data-aos="fade-up">
            {t('aboutUs.contents.content1')}
          </p>

          <p className="about-us-page-content-item" data-aos="fade-up">
            {t('aboutUs.contents.content2')}
          </p>

          <p className="about-us-page-content-item" data-aos="fade-up">
            {t('aboutUs.contents.content3')}
          </p>

          <p className="about-us-page-content-item" data-aos="fade-up">
            {t('aboutUs.contents.content4')}
          </p>

          <p className="about-us-page-content-item" data-aos="fade-up">
            {t('aboutUs.contents.content5')}
          </p>

          <p className="about-us-page-content-item" data-aos="fade-up">
            {t('aboutUs.contents.content6')}
          </p>

          <Grid
            container
            spacing={4}
            columns={{ lg: 12, md: 12, xs: 12 }}
            className="about-us-items"
          >
            <Grid
              item={true}
              lg={4}
              md={6}
              xs={12}
              className="about-us-item"
              component={motion.div}
              variants={fadeUp(30, 'tween', 0, 0.5)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true }}
            >
              <div className="about-us-card">
                <div className="about-us-card-content-wrapper">
                  <div className="about-us-card-logo">
                    <RocketLaunchOutlined className="!text-5xl" />
                  </div>
                  <div className="about-us-card-title">
                    {t('aboutUs.card.card1.title')}
                  </div>
                  <div className="about-us-card-content-body">
                    <p
                      className={`text-justify${
                        showFirstCard ? '' : ' text-line-[10]'
                      }`}
                    >
                      {t('aboutUs.card.card1.content')}
                    </p>
                    <div
                      className="about-us-card-arrow-wrapper"
                      onClick={() => setShowFirstCard(!showFirstCard)}
                    >
                      {showFirstCard ? (
                        <KeyboardArrowUp className="text-base-color" />
                      ) : (
                        <KeyboardArrowDown className="text-base-color" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid
              item={true}
              lg={4}
              md={6}
              xs={12}
              className="about-us-item"
              component={motion.div}
              variants={fadeUp(30, 'tween', 0.2, 0.5)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true }}
            >
              <div className="about-us-card">
                <div className="about-us-card-content-wrapper">
                  <div className="about-us-card-logo">
                    <LightbulbOutlined className="!text-5xl" />
                  </div>
                  {/* <div className="about-us-card-title flex items-center justify-center text-2xl text-center" style={{height: titleHeightRef && titleHeightRef.current ? titleHeightRef.current.clientHeight : "auto"}}> */}
                  <div className="about-us-card-title">
                    {t('aboutUs.card.card2.title')}
                  </div>
                  <div className="about-us-card-content-body">
                    <p
                      className={`text-justify${
                        showSecondCard ? '' : ' text-line-[10]'
                      }`}
                    >
                      {t('aboutUs.card.card2.content')}
                    </p>

                    <div
                      className="about-us-card-arrow-wrapper"
                      onClick={() => setShowSecondCard(!showSecondCard)}
                    >
                      {showSecondCard ? (
                        <KeyboardArrowUp className="text-base-color" />
                      ) : (
                        <KeyboardArrowDown className="text-base-color" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid
              item={true}
              lg={4}
              md={6}
              xs={12}
              className="about-us-item"
              component={motion.div}
              variants={fadeUp(30, 'tween', 0.4, 0.5)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true }}
            >
              <div className="about-us-card">
                <div className="about-us-card-content-wrapper">
                  <div className="about-us-card-logo">
                    <BoltOutlined className="!text-5xl" />
                  </div>
                  {/* <div className="about-us-card-title flex items-center justify-center text-2xl text-center" ref={titleHeightRef} style={{height: titleHeightRef && titleHeightRef.current ? titleHeightRef.current.clientHeight : "auto"}}> */}
                  <div className="about-us-card-title">
                    {t('aboutUs.card.card3.title')}
                  </div>
                  <div className="about-us-card-content-body">
                    <p
                      className={`text-justify${
                        showThirdCard ? '' : ' text-line-[10]'
                      }`}
                    >
                      {t('aboutUs.card.card3.content')}
                    </p>
                    <div
                      className="about-us-card-arrow-wrapper"
                      onClick={() => setShowThirdCard(!showThirdCard)}
                    >
                      {showThirdCard ? (
                        <KeyboardArrowUp className="text-base-color" />
                      ) : (
                        <KeyboardArrowDown className="text-base-color" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Contact />
    </>
  );
};

export default AboutUs;
