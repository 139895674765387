import { Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fadeUp } from 'utils/motion';
import getLocaleValue from '../../utils/getLocaleValue';

export const CourseCard = ({ data, index }) => {
  const { t } = useTranslation();

  return (
    <Grid
      key={data.id}
      item={true}
      xl={2}
      md={4}
      sm={6}
      xs={12}
      className="course-item"
      initial="hidden"
      animate="show"
      viewport={{ once: true }}
      component={motion.div}
      variants={fadeUp(30, 'tween', index * 0.2, 0.5)}
    >
      <div
        className="border border-gray-300 rounded-lg overflow-hidden shadow-md"
        data-aos="fade-up"
      >
        <img
          width={'100%'}
          height={180}
          loading="lazy"
          className="object-cover"
          src={data?.image?.url}
          alt={data?.image?.filename}
        />

        <div className="p-4">
          <h3 className="font-bold mb-2">{getLocaleValue(data, 'title')}</h3>
          <p className="text-sm">
            <span>{t('course.category')}: </span>
            <span>{data?.category}</span>
          </p>
          <p className="font-bold mt-2">${data?.price}</p>

          <Link
            to={`/course/${data?.path}`}
            className="no-underline text-center inline-block border border-base-color text-base-color px-4 py-2 rounded-md mt-4 w-full hover:bg-base-color hover:text-white transition"
          >
            {t('courses.more')}
          </Link>
        </div>
      </div>
    </Grid>
  );
};
