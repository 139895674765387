import i18n from '../config/i18n';

const getLocaleValue = (obj, field) => {
  if (obj.hasOwnProperty(field)) {
    if (i18n.language === 'ru') {
      return obj[field + 'Ru'];
    }

    if (i18n.language === 'en') {
      return obj[field + 'En'];
    }

    return obj[field];
  }
};

export default getLocaleValue;
