import './assets/css/main.scss';
import './assets/css/tailwind.scss';
import 'slick-carousel/slick/slick.css';
import './assets/css/custom-mui-styles.scss';
import 'slick-carousel/slick/slick-theme.css';
import './config/i18n';
import 'aos/dist/aos.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import Aos from 'aos';
import App from './app';

Aos.init();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
