import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Container } from '../../layouts';
import getLocaleValue from 'utils/getLocaleValue';
import { useDataQueries } from 'hooks/useDataQueries';
import { fromBase64ToTextParser } from 'utils/textParser';

const CourseContent = ({ course, language }) => (
  <div
    className="text-lg"
    dangerouslySetInnerHTML={{
      __html: fromBase64ToTextParser(
        course[`content${language.charAt(0).toUpperCase() + language.slice(1)}`]
      ),
    }}
  />
);

const CourseItem = () => {
  const { t, i18n } = useTranslation();
  const { coursePath } = useParams();
  const { courseQuery } = useDataQueries();
  const navigate = useNavigate();
  let [localCourse, setLocalCourse] = useState({});

  useEffect(() => {
    const getCourse = (path) => {
      let course = courseQuery.data.filter((item) => item?.path === path);
      if (course.length) {
        setLocalCourse(course[0]);
      } else {
        navigate('/not-found', { replace: true });
      }
    };

    if (courseQuery.data) {
      getCourse(coursePath);
    }
  }, [courseQuery.data, coursePath, navigate]);

  return (
    <section>
      <Container>
        {courseQuery.isLoading || courseQuery.isFetching ? (
          <div className="circular-progress-box">
            <CircularProgress size={35} />
          </div>
        ) : localCourse ? (
          <>
            <div className="py-6 flex justify-between items-center pt-14">
              <div className="max-w-[650px]" data-aos="fade-up">
                <h2 className="xl:text-3xl sm:text-[33px] text-3xl text-gray-800 font-medium mb-6">
                  {getLocaleValue(localCourse, 'title')}
                </h2>

                <CourseContent course={localCourse} language={i18n.language} />
              </div>

              <div
                className="border border-gray-400 rounded-lg overflow-hidden"
                data-aos="fade-up"
              >
                <img
                  width={300}
                  height={180}
                  loading="lazy"
                  className="object-cover"
                  src={localCourse?.image?.url}
                  alt={localCourse?.image?.filename || 'placeholder-image'}
                />

                <div className="p-4">
                  <p>
                    <span>{t('course.category')}: </span>
                    <span>{localCourse?.category}</span>
                  </p>
                  <p>
                    <span>{t('course.duration')}: </span>
                    <span>
                      {localCourse?.duration}{' '}
                      {t(
                        `course.durationTypeList.${localCourse?.durationUnit}`
                      )}
                    </span>
                  </p>
                  <p className="text-xl font-bold my-2">
                    ${localCourse?.price}
                  </p>

                  <button className="border border-base-color text-base-color px-4 py-2 rounded-md mt-4 w-full hover:bg-base-color hover:text-white transition">
                    Buy course
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="item-not-found"></div>
        )}
      </Container>
    </section>
  );
};

export default CourseItem;
