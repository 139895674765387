import { useQuery } from 'react-query';

import axios from 'api/axios';

const closeIcon = (
  <svg
    style={{ width: '20px' }}
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M6 18L18 6M6 6l12 12"
    ></path>
  </svg>
);

export const FilterDrawer = ({ openDrawer, setOpenDrawer, setCategory }) => {
  const { data } = useQuery({
    queryKey: 'courseCategorySelect',
    queryFn: async function () {
      const response = await axios.get('/course/category');
      return response.data.items[0];
    },
    retry: false,
  });

  if (!openDrawer) return null;

  const onClose = () => setOpenDrawer(false);
  const selectCategory = (evt) => {
    setCategory(evt.target.value);
  };

  return (
    <div className="fixed top-0 right-0 w-full h-full z-50 flex">
      <div
        onClick={onClose}
        className="w-0 md:w-3/4 h-full w-full bg-black opacity-50"
      ></div>

      <div className="w-full md:w-1/4 bg-white h-full ml-auto p-6">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-bold">Filter</h3>
          <button
            onClick={onClose}
            className="p-[3px] text-gray-400 border border-gray-400 hover:text-gray-500 hover:border-gray-400"
          >
            {closeIcon}
          </button>
        </div>

        <form className="py-4">
          <div className="mb-3">
            <label htmlFor="category" className="block mb-2">
              Select Category
            </label>
            <select
              id="category"
              className="border border-gray-400 rounded p-2 outline-none w-full cursor-pointer hover:border-gray-500"
              onChange={selectCategory}
            >
              <option value="all">All</option>
              {data.categories.map((category) => (
                <option value={category} key={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          <button
            type="reset"
            className="px-4 py-1 rounded bg-base-color text-white hover:opacity-80 transition"
          >
            Reset filter
          </button>
        </form>
      </div>
    </div>
  );
};
