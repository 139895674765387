export const projects = [
  {
    id: 1,
    connection_voltage: '33kV',
    installed_capacity: '9.9 MW',
    en: {
      name: 'Orchard Bank Battery Storage Site',
      client: 'Orchard Bank Storage Ltd',
      location: 'Forfar, Scotland',
      scope_description:
        'Mirpower carried out the following technical studies and assessments for the connection of the 9.9MW battery energy storage system to the local distribution network',
      scopes: [
        'G99 Studies: Assessed compliance of the proposed installation with Grid Code requirements such as fault ride through capability.',
        'P28 Studies: Analyzed protection settings and schemes to satisfy distribution network operator requirements.',
        'Protection Coordination Studies: Determined protection settings and coordination between generator and network protections.',
        'Load Flow and Short Circuit Current Studies: Analyzed the impact of injection/absorption of power on voltage levels and fault currents.',
        'Harmonic Analysis (G5/4): Assessed harmonic emissions from the installation and compliance with limits.',
      ],
    },
    ru: {
      name: 'Orchard Bank Battery Storage Site',
      client: 'Orchard Bank Storage Ltd',
      location: 'Форфар, Шотландия',
      scope_description:
        'Компания Mirpower выполнила следующие технические исследования и оценки для подключения аккумуляторной системы хранения энергии мощностью 9,9 МВт к местной распределительной сети:',
      scopes: [
        'Исследования G99: Оценено соответствие предлагаемой установки требованиям Сетевого кодекса, таким как способность преодолевать неисправности.',
        'Исследования P28: Проанализированы настройки и схемы защиты для удовлетворения требований оператора распределительной сети.',
        'Исследования координации защиты: Определены настройки защиты и координация между защитой генератора и сети.',
        'Исследования потоков нагрузки и токов короткого замыкания: проанализировано влияние подачи/поглощения мощности на уровни напряжения и токи повреждения.',
        'Гармонический анализ (G5/4): оценка гармонических выбросов от установки и соответствие предельным значениям.',
      ],
    },
    uz: {
      name: 'Orchard Bank Battery Storage Site',
      client: 'Orchard Bank Storage Ltd',
      location: 'Forfar, Shotlandiya',
      scope_description:
        "Mirpower kompaniyasi 9,9 MVt quvvatga ega batareya quvvatini saqlash tizimini mahalliy tarqatish tarmog'iga ulash bo'yicha quyidagi texnik tadqiqotlar va baholashlarni amalga oshirdi:",
      scopes: [
        "G99 tadqiqotlari: Tavsiya etilgan o'rnatishning Grid Code talablariga muvofiqligi baholandi, masalan, nosozliklarni bartaraf etish qobiliyati.",
        "P28 Tadqiqotlar: Tarqatish tarmog'i operatori talablarini qondirish uchun himoya sozlamalari va sxemalari tahlil qilindi.",
        "Himoyani muvofiqlashtirish bo'yicha tadqiqotlar: Aniqlangan himoya sozlamalari va generator va tarmoq himoyasi o'rtasidagi muvofiqlashtirish.",
        "Yuk oqimi va qisqa tutashuv oqimini o'rganish: quvvatni in'ektsiya qilish/yutishning kuchlanish darajasiga va nosozlik oqimlariga ta'sirini tahlil qildi.",
        "Garmonik tahlil (G5/4): O'rnatishdan olingan harmonik emissiyalar va cheklovlarga muvofiqligi.",
      ],
    },
  },
  {
    id: 2,
    connection_voltage: '33kV',
    installed_capacity: '9.9 MW',
    en: {
      name: 'Braco BESS',
      client: 'Orchard Bank Storage Ltd',
      location: 'Forfar, Scotland',
      scope_description:
        'Mirpower carried out the following technical studies and assessments for the connection of the 9.9MW battery energy storage system to the local distribution network:',
      scopes: [
        'G99 Studies: Assessed compliance of the proposed installation with Grid Code requirements such as fault ride through capability.',
        'P28 Studies: Analyzed protection settings and schemes to satisfy distribution network operator requirements.',
        'Protection Coordination Studies: Determined protection settings and coordination between generator and network protections.',
        'Load Flow and Short Circuit Current Studies: Analyzed the impact of injection/absorption of power on voltage levels and fault currents.',
        'Harmonic Analysis (G5/4): Assessed harmonic emissions from the installation and compliance with limits.',
      ],
    },
    ru: {
      name: 'Braco BESS',
      client: 'Orchard Bank Storage Ltd',
      location: 'Форфар, Шотландия',
      scope_description:
        'Компания Mirpower выполнила следующие технические исследования и оценки для подключения аккумуляторной системы хранения энергии мощностью 9,9 МВт к местной распределительной сети:',
      scopes: [
        'Исследования G99: Оценено соответствие предлагаемой установки требованиям Сетевого кодекса, таким как способность преодолевать неисправности.',
        'Исследования P28: Проанализированы настройки и схемы защиты для удовлетворения требований оператора распределительной сети.',
        'Исследования координации защиты: Определены настройки защиты и координация между защитой генератора и сети.',
        'Исследования потоков нагрузки и токов короткого замыкания: проанализировано влияние подачи/поглощения мощности на уровни напряжения и токи повреждения.',
        'Гармонический анализ (G5/4): оценка гармонических выбросов от установки и соответствие предельным значениям.',
      ],
    },
    uz: {
      name: 'Braco BESS',
      client: 'Orchard Bank Storage Ltd',
      location: 'Forfar, Shotlandiya',
      scope_description:
        "Mirpower kompaniyasi 9,9 MVt quvvatga ega batareya quvvatini saqlash tizimini mahalliy tarqatish tarmog'iga ulash bo'yicha quyidagi texnik tadqiqotlar va baholashlarni amalga oshirdi:",
      scopes: [
        "G99 tadqiqotlari: Tavsiya etilgan o'rnatishning Grid Code talablariga muvofiqligi baholandi, masalan, nosozliklarni bartaraf etish qobiliyati.",
        "P28 Tadqiqotlar: Tarqatish tarmog'i operatori talablarini qondirish uchun himoya sozlamalari va sxemalari tahlil qilindi.",
        "Himoyani muvofiqlashtirish bo'yicha tadqiqotlar: Aniqlangan himoya sozlamalari va generator va tarmoq himoyasi o'rtasidagi muvofiqlashtirish.",
        "Yuk oqimi va qisqa tutashuv oqimini o'rganish: quvvatni in'ektsiya qilish/yutishning kuchlanish darajasiga va nosozlik oqimlariga ta'sirini tahlil qildi.",
        "Garmonik tahlil (G5/4): O'rnatishdan olingan harmonik emissiyalar va cheklovlarga muvofiqligi.",
      ],
    },
  },
  {
    id: 3,
    connection_voltage: '33kV',
    installed_capacity: '9.9 MW',
    en: {
      name: 'ERCOT Solar PV Project 1',
      client: 'Sunny Solar LLC',
      location: 'Texas, USA',
      scope_description:
        'Mirpower carried out the following technical studies and assessments for the connection of the 9.9MW battery energy storage system to the local distribution network:',
      scopes: [
        'Determined optimal plant size based on local 230kV  thermal ratings - Recommended 200MW plant size',
        'Performed load flow studies to assess impact on voltage levels',
        'Developed short circuit contribution models for fault analysis',
        'Coordinated with ERCOT on technical requirements',
        "Compiled and submitted Interconnection Request application to ERCOT on client's behalf",
      ],
    },
    ru: {
      name: 'ERCOT Solar PV Project 1',
      client: 'Sunny Solar LLC',
      location: 'Texac, США',
      scope_description:
        'Компания Mirpower выполнила следующие технические исследования и оценки для подключения аккумуляторной системы хранения энергии мощностью 9,9 МВт к местной распределительной сети:',
      scopes: [
        'Determined optimal plant size based on local 230kV  thermal ratings - Recommended 200MW plant size',
        'Performed load flow studies to assess impact on voltage levels',
        'Developed short circuit contribution models for fault analysis',
        'Coordinated with ERCOT on technical requirements',
        "Compiled and submitted Interconnection Request application to ERCOT on client's behalf",
      ],
    },
    uz: {
      name: 'ERCOT Solar PV Project 1',
      client: 'Sunny Solar LLC',
      location: 'Texas, AQSh',
      scope_description:
        "Mirpower kompaniyasi 9,9 MVt quvvatga ega batareya quvvatini saqlash tizimini mahalliy tarqatish tarmog'iga ulash bo'yicha quyidagi texnik tadqiqotlar va baholashlarni amalga oshirdi:",
      scopes: [
        'Determined optimal plant size based on local 230kV  thermal ratings - Recommended 200MW plant size',
        'Performed load flow studies to assess impact on voltage levels',
        'Developed short circuit contribution models for fault analysis',
        'Coordinated with ERCOT on technical requirements',
        "Compiled and submitted Interconnection Request application to ERCOT on client's behalf",
      ],
    },
  },
  {
    id: 4,
    connection_voltage: '33kV',
    installed_capacity: '9.9 MW',
    en: {
      name: 'ERCOT Solar PV Project 2',
      client: 'Sunshine Generators Inc',
      location: 'Texas, USA',
      scope_description:
        'Mirpower carried out the following technical studies and assessments for the connection of the 9.9MW battery energy storage system to the local distribution network:',
      scopes: [
        'Identical scope as ERCOT Solar PV Project 1 above, but for a 10MW plant to fill available grid capacity',
        'Coordinated all required technical studies and information exchange with ERCOT',
        "Acted as the client's representative in the application and project coordination process",
      ],
    },
    ru: {
      name: 'ERCOT Solar PV Project 2',
      client: 'Sunshine Generators Inc',
      location: 'Texac, США',
      scope_description:
        'Компания Mirpower выполнила следующие технические исследования и оценки для подключения аккумуляторной системы хранения энергии мощностью 9,9 МВт к местной распределительной сети:',
      scopes: [
        'Объем идентичен проекту ERCOT Solar PV Project 1, указанному выше, но для электростанции мощностью 10 МВт для заполнения доступной мощности сети.',
        'Координировал все необходимые технические исследования и обмен информацией с ERCOT.',
        'Выступал представителем клиента в процессе подачи заявки и согласования проекта.',
      ],
    },
    uz: {
      name: 'ERCOT Solar PV Project 2',
      client: 'Sunshine Generators Inc',
      location: 'Texas, AQSh',
      scope_description:
        "Mirpower kompaniyasi 9,9 MVt quvvatga ega batareya quvvatini saqlash tizimini mahalliy tarqatish tarmog'iga ulash bo'yicha quyidagi texnik tadqiqotlar va baholashlarni amalga oshirdi:",
      scopes: [
        "Yuqoridagi ERCOT Solar PV loyihasi 1 bilan bir xil, ammo mavjud tarmoq quvvatini to'ldirish uchun 10 MVt quvvatga ega stansiya uchun",
        "ERCOT bilan barcha kerakli texnik tadqiqotlar va ma'lumotlar almashinuvini muvofiqlashtirdi",
        'Ariza topshirish va loyihani muvofiqlashtirish jarayonida mijozning vakili sifatida ishlagan',
      ],
    },
  },
];
