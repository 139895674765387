import { useTranslation } from 'react-i18next';
import { PrimaryLink } from '../../../components/primary-link';
import { Container } from '../../../layouts';
import { SectionTitle } from '../../../components/section-title';

export const About = () => {
  const { t } = useTranslation();

  return (
    <section className="py-14">
      <Container>
        <SectionTitle>{t('aboutUs.title')}</SectionTitle>

        <p
          className="mt-5 text-lg text-line-[10] sm:text-line-[15] md:block text-justify mb-10"
          data-aos="fade-up"
        >
          {t('aboutUs.homeContent')}
        </p>

        <PrimaryLink to="/about" className="mx-auto text-center">
          {t('common.button.more')}
        </PrimaryLink>
      </Container>
    </section>
  );
};
