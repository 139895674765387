import { motion } from "framer-motion"
import { fadeUp, textContainer, textVariant2 } from "utils/motion"

export const TypingText = ({ title, textStyles }) => (
	<motion.p variants={textContainer} className={textStyles}>
		{Array.from(title).map((letter, index) => (
			<motion.span variants={textVariant2} key={index}>
				{letter === " " ? "\u00A0" : letter}
			</motion.span>
		))}
	</motion.p>
)

export const SectionTitleText = ({ title, className, duration = 0.5 }) => (
	<p className={className}>
		{Array.from(title).map((letter, index) => (
			<motion.span
				variants={textVariant2(index * (duration / Array.from(title).length))}
				initial="hidden"
				whileInView="show"
				viewport={{ once: true, amount: 0.25 }}
				key={index}
			>
				{letter === " " ? "\u00A0" : letter}
			</motion.span>
		))}
	</p>
)

export const FadeUpText = ({
	children,
	delay = 0.2,
	className,
	once = true
}) => (
	<motion.div
		variants={fadeUp(30, "spring", delay, 1)}
		initial="hidden"
		whileInView="show"
		viewport={{ once: once, amount: 0.25 }}
		className={className}
	>
		{children}
	</motion.div>
)
