import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTelegram } from '@fortawesome/free-brands-svg-icons';

import { Container } from '../layouts';
import LightTooltip from 'components/ui/tooltips/LightTooltip';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="pt-6">
      <Container>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          className="footer-panel-links"
          columns={{ xs: 4, sm: 8, md: 12, xl: 7 }}
        >
          <Grid item={true} xl={3} sm={4} xs={12}>
            <div className="footer-item-title">
              {t('footer.contacts.title')}
            </div>
            <div className="footer-item-body">
              <div className="footer-phone-contacts">
                <a href="tel:+998934619791" className="hover:text-white">
                  +998 93 461-97-91
                </a>
              </div>
              <div className="footer-phone-contacts">
                <a
                  href="mailto:megamzod@mirpower.org"
                  className="hover:text-white"
                >
                  megamzod@mirpower.org
                </a>
              </div>
            </div>
          </Grid>

          <Grid item={true} xl={3} sm={4} xs={12} className="mr-auto">
            <div className="footer-item-title mt-5">
              {t('footer.location.title')}
            </div>
            <div className="footer-item-body">
              <div className="footer-location-address max-w-[300px]">
                {t('footer.location.address')}
              </div>
            </div>
          </Grid>

          <Grid xl={1} sm={4} xs={12} item={true}>
            <div className="footer-item-title mt-5">
              {t('footer.followUs.title')}
            </div>
            <div className="footer-item-body">
              <div className="footer-follow-media">
                <LightTooltip title="LinkedIn" placement="top" arrow>
                  <Link
                    to="https://www.linkedin.com/company/mirpower/"
                    target="_blank"
                    className="follow-media-link hover:text-[#0a66c2] no-underline"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </Link>
                </LightTooltip>

                <LightTooltip title="Telegram" placement="top" arrow>
                  <Link
                    to="https://t.me"
                    target="_blank"
                    className="follow-media-link hover:text-[#0088cc] no-underline"
                  >
                    <FontAwesomeIcon icon={faTelegram} />
                  </Link>
                </LightTooltip>
              </div>
            </div>
          </Grid>
        </Grid>

        <div className="border-b-2 border-gray-400 ml-6" />

        <div className="my-5 text-center  text-white">
          © 2024 Mirpower. {t('footer.allRights')}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
