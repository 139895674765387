import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { Container } from '../../../layouts';
import { useDataQueries } from 'hooks/useDataQueries';
import { CourseCard } from '../../../components/course-card';
import { SectionTitle } from '../../../components/section-title';
import { PrimaryLink } from '../../../components/primary-link';

export const Courses = () => {
  const { t } = useTranslation();

  const { courseQuery } = useDataQueries();

  return (
    <section className="py-14">
      <Container>
        <SectionTitle>{t('course.title')}</SectionTitle>

        {courseQuery.isLoading || courseQuery.isFetching ? (
          <div className="circular-progress-box py-5">
            <CircularProgress size={35} />
          </div>
        ) : courseQuery.data && courseQuery.data.length > 0 ? (
          <>
            <Grid
              container
              spacing={4}
              columns={{ xl: 8, md: 12, sm: 12, xs: 12 }}
            >
              {courseQuery.data
                .sort(
                  (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
                )
                .slice(0, 4)
                .map((item, index) => (
                  <CourseCard data={item} index={index} key={index} />
                ))}
            </Grid>

            <PrimaryLink to="/course" className="mx-auto mt-10">
              {t('common.button.more')}
            </PrimaryLink>
          </>
        ) : (
          <span className="no-data-found-wrapper">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
            {t('common.global.noDataFound')}
          </span>
        )}
      </Container>
    </section>
  );
};
