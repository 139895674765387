import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { services } from '../../data/service';
import { Container } from '../../layouts';
import { Contact } from '../../components/contact';

const ServiceItem = () => {
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation();
  const { servicePath } = useParams();

  let [localService, setLocalService] = React.useState({});

  React.useEffect(() => {
    let foundService = services.filter(
      (item) => item.service_id === servicePath - 0
    );
    if (foundService.length > 0) {
      setLocalService(foundService[0]);
    } else {
      navigate('/not-found', { replace: true });
    }
  }, [navigate, servicePath]);

  return (
    <section className="py-14">
      <Container>
        <h2
          className="xl:text-4xl sm:text-[33px] text-3xl text-gray-800 font-medium mb-8 text-center"
          data-aos="fade-up"
        >
          {localService[language]?.service_name}
        </h2>

        <p
          className="my-5 text-lg md:block text-justify mb-10"
          data-aos="fade-up"
        >
          {localService[language]?.intro_text}
        </p>

        <div className="flex justify-between items-center mb-8">
          <div data-aos="fade-up">
            <h3 className="font-semibold text-3xl mb-4">
              {localService[language]?.why_us}
            </h3>
            <ul className="list-disc max-w-[650px]">
              <li className="mb-3">
                <p className="text-lg">
                  <bold className="font-semibold">
                    Expertise in Power System Analysis:
                  </bold>
                  Our team is well-versed in utilizing DigSILENT PowerFactory, a
                  leading power system analysis tool, to conduct detailed RMS
                  simulations.
                </p>
              </li>
              <li className="mb-3">
                <p className="text-lg">
                  <bold className="font-semibold">Customized Solutions:</bold>
                  We understand that each power system has its unique
                  challenges. Our approach is to provide tailored simulations
                  that cater specifically to your project's requirements,
                  ensuring that all aspects of your power system are thoroughly
                  evaluated.
                </p>
              </li>
              <li className="mb-3">
                <p className="text-lg">
                  <bold className="font-semibold">
                    Advanced Simulation Capabilities:
                  </bold>
                  Our service includes a range of RMS simulations such as load
                  flow studies, fault analysis, harmonics, dynamic simulations,
                  and stability studies. These simulations are crucial for the
                  planning, design, and operation of efficient and reliable
                  power systems.
                </p>
              </li>
              <li className="mb-3">
                <p className="text-lg">
                  <bold className="font-semibold">
                    Ongoing Support and Consultation:
                  </bold>
                  We believe in building lasting relationships with our clients.
                  As part of our service, we offer ongoing support and
                  consultation to address any future power system challenges you
                  might face.
                </p>
              </li>
            </ul>
          </div>

          <img
            src={localService.service_img}
            alt="Service"
            loading="lazy"
            width={400}
            data-aos="fade-up"
          />
        </div>

        <p className="text-lg mb-14" data-aos="fade-up">
          At MIRPOWER , we are committed to supporting the growth of clean
          energy with our specialized services, paving the way for a more
          sustainable and secure energy future.
        </p>

        <Contact />
      </Container>
    </section>
  );
};

export default ServiceItem;
