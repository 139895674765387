import React from 'react';
import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { servicesList } from '../../data';
import { Container } from '../../layouts';
import { ServiceCard } from '../../components/service-card';
import { Contact } from '../../components/contact';

const Services = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Services</title>
        <meta property="og:title" content="Services" />
        <link rel="canonical" href="https://mirpower.uz/service" />
        <meta name="description" content="Mirpower - Services" />
        <meta property="og:description" content="Mirpower - Services" />
      </Helmet>

      <section className="py-6">
        <Container>
          <h2
            className="xl:text-4xl sm:text-[33px] text-3xl text-gray-800 font-medium mb-6 text-center"
            data-aos="fade-up"
          >
            {t('service.title')}
          </h2>
        </Container>
      </section>

      <section className="pb-10">
        <Container>
          <Grid container spacing={4} columns={{ lg: 12, md: 12, sm: 12 }}>
            {servicesList.map((item, index) => (
              <ServiceCard data={item} index={index} key={item.id} />
            ))}
          </Grid>
        </Container>
      </section>

      <Contact />
    </>
  );
};

export default Services;
