import { Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { fadeUp } from 'utils/motion';

export const ServiceCard = ({ data, index }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Grid
      key={data.id}
      item={true}
      lg={3}
      md={6}
      sm={12}
      component={motion.div}
      variants={fadeUp(30, 'tween', index * 0.2, 0.5)}
      initial="hidden"
      animate="show"
      viewport={{ once: true }}
    >
      <div className="border rounded-lg shadow-xl p-5 flex flex-col items-center bg-white h-full">
        <img
          className="rounded-full mb-2 object-cover shrink-0 h-[100px]"
          src={data?.service_img}
          alt="Service img"
          width={100}
          height={100}
        />
        <h3 className="font-bold text-lg mb-2 text-center">
          {data[language].name}
        </h3>
        <p className="mb-4 text-center text-sm grow">
          {data[language].description}
        </p>
        <Link
          className="no-underline text-center inline-block text-base-color rounded-md font-medium hover:opacity-80 hover:scale-105 transition"
          to={`/service/${data?.id}`}
        >
          {t('services.read_more')}
        </Link>
      </div>
    </Grid>
  );
};
