import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Container } from '../../layouts';
import { Contact } from '../../components/contact';
import { projects } from '../../data/projects/projects';
import { SectionTitle } from '../../components/section-title';

const ProjectItem = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const { projectPath } = useParams();
  let [localProject, setLocalProject] = React.useState({});

  React.useEffect(() => {
    let project = projects.filter((item) => item.id === projectPath - 0);

    if (project.length) {
      setLocalProject(project[0]);
    } else {
      navigate('/not-found', { replace: true });
    }
  }, [projectPath, navigate]);

  return (
    <>
      <section className="py-12">
        <Container>
          <SectionTitle>Project Details</SectionTitle>

          <div className="bg-gray-200 p-4" data-aos="fade-up">
            <div className="flex mb-2">
              <p className="mr-10">
                Client:{' '}
                <strong className="ml-1">
                  {localProject[language]?.client}
                </strong>
              </p>
              <p>
                Location:{' '}
                <strong className="ml-1">
                  {localProject[language]?.location}
                </strong>
              </p>
            </div>
            <div className="flex">
              <p className="mr-10">
                Connection Voltage:{' '}
                <strong className="ml-1">
                  {localProject.connection_voltage}
                </strong>
              </p>
              <p>
                Installed Capacity:{' '}
                <strong className="ml-1">
                  {localProject.installed_capacity}
                </strong>
              </p>
            </div>
          </div>
        </Container>
      </section>

      <section className="py-12">
        <Container>
          <SectionTitle>Scope of Work</SectionTitle>

          <p
            className="mt-5 text-lg md:block text-justify mb-8"
            data-aos="fade-up"
          >
            {localProject[language]?.scope_description}
          </p>

          <ul>
            {localProject[language]?.scopes.map((project) => (
              <li className="ml-6 mb-4 text-base flex" data-aos="fade-up">
                <span className="mr-2">✅</span>
                <p>{project}</p>
              </li>
            ))}
          </ul>
        </Container>
      </section>

      <Contact />
    </>
  );
};

export default ProjectItem;
