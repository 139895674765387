import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container } from '../../layouts';
import { projectsList } from '../../data/projects';
import { Contact } from '../../components/contact';
import { ProjectCard } from '../../components/project-card';
import { SectionTitle } from '../../components/section-title';

const Projects = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Projects</title>
        <meta property="og:title" content="Projects" />
        <link rel="canonical" href="https://mirpower.org/project" />
        <meta name="description" content="Mirpower - Projects" />
        <meta property="og:description" content="Mirpower - Projects" />
      </Helmet>

      <section className="py-14">
        <Container>
          <SectionTitle>{t('project.title')}</SectionTitle>

          <div className="flex justify-between flex-wrap items-stretch">
            {projectsList.map((project, i) => (
              <ProjectCard key={project.id} project={project} index={i} />
            ))}
          </div>
        </Container>
      </section>

      <Contact />
    </>
  );
};

export default Projects;
