import { Helmet } from 'react-helmet';

import BaseSlider from 'components/slider/BaseSlider';
import { About } from './about/about';
import { Services } from './services';
import { Projects } from './projects';
import { Courses } from './courses';
import { Contact } from '../../components/contact';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Mirpower</title>
        <meta property="og:title" content="Mirpower" />
        <meta
          name="description"
          content="Mirpower projects - Make your dream power projects with us!"
        />
        <link rel="canonical" href="https://mirpower.uz" />
      </Helmet>

      <div className="home-slick-slider-wrapper">
        <BaseSlider />
      </div>

      <About />

      <Services />

      <Projects />

      <Courses />

      <Contact />
    </>
  );
};

export default Home;
