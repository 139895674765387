import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '@uidotdev/usehooks';
import { CircularProgress, Grid } from '@mui/material';

import axios from 'api/axios';
import { Container } from '../../layouts';
import { FilterButtons, FilterDrawer } from './filter';
import { CourseCard } from '../../components/course-card';
import { Contact } from '../../components/contact';

const Courses = () => {
  const { t } = useTranslation();

  const [courses, setCourses] = React.useState([]);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [searchText, setSearchTextText] = React.useState('');
  const [category, setCategory] = React.useState('all');

  const debouncedSearchTerm = useDebounce(searchText, 300);

  const { isLoading, data } = useQuery({
    queryKey: 'coursesSearch',
    queryFn: async function () {
      let requestData = {
        page: 0,
        size: 500,
      };
      const response = await axios.post('/course/list-search', requestData);

      return response.data;
    },
    retry: false,
  });

  React.useEffect(() => {
    if (!debouncedSearchTerm && category === 'all' && data) {
      return setCourses(data.items);
    }

    let filteredData = [];

    if (debouncedSearchTerm) {
      filteredData = data.items.filter(
        (course) =>
          course.title.toLowerCase().includes(debouncedSearchTerm) ||
          course.titleEn.toLowerCase().includes(debouncedSearchTerm) ||
          course.titleRu.toLowerCase().includes(debouncedSearchTerm)
      );
    } else {
      filteredData = data?.items || [];
    }

    if (category !== 'all') {
      filteredData = filteredData.filter(
        (item) => item.category.toLowerCase() === category.toLowerCase()
      );
    }

    setCourses(filteredData);
  }, [debouncedSearchTerm, data, category]);

  return (
    <>
      <Helmet>
        <title>Courses</title>
        <meta property="og:title" content="Courses" />
        <link rel="canonical" href="https://mirpower.uz/course" />
        <meta name="description" content="Mirpower - Courses" />
        <meta property="og:description" content="Mirpower - Courses" />
      </Helmet>

      <section className="pt-6 pb-14">
        <Container>
          <h2
            className="xl:text-4xl sm:text-[33px] text-3xl text-gray-800 font-medium mb-4 text-center"
            data-aos="fade-up"
          >
            {t('course.title')}
          </h2>

          <FilterButtons
            setOpenDrawer={setOpenDrawer}
            setSearchTextText={setSearchTextText}
          />
        </Container>

        {isLoading && (
          <div className="circular-progress-box py-5">
            <CircularProgress size={35} />
          </div>
        )}

        {!isLoading && (
          <Container>
            <Grid
              container
              spacing={4}
              columns={{ xl: 8, md: 12, sm: 12, xs: 12 }}
              className="course-items"
            >
              {courses.map((item, index) => (
                <CourseCard data={item} index={index} key={index} />
              ))}
            </Grid>
          </Container>
        )}
      </section>

      <Contact />

      <FilterDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setCategory={setCategory}
      />
    </>
  );
};

export default Courses;
