export const services = [
  {
    id: 1,
    service_id: 1,
    service_img: 'https://telegra.ph/file/a77ca35dff709b3ff3deb.jpg',
    en: {
      service_name: 'Consulting',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Why Choose Our Services?',
      get_in_touch: 'Get in touch',
      question: 'If you have any question contact us!',
    },
    ru: {
      service_name: 'Консалтинг',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Почему выбирают наши услуги?',
      get_in_touch: 'Связаться',
      question: 'Если у вас есть какие-либо вопросы, свяжитесь с нами!',
    },
    uz: {
      service_name: 'Konsalting',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Nima uchun bizning xizmatlarni tanlaysiz?',
      get_in_touch: "Biz bilan bog'laning",
      question: "Savollaringiz bo'lsa, biz bilan bog'laning!",
    },
  },
  {
    id: 2,
    service_id: 2,
    service_img: 'https://telegra.ph/file/b0cd2d8093474095860db.png',
    en: {
      service_name: 'Insulation Coordination',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Why Choose Our Services?',
      get_in_touch: 'Get in touch',
      question: 'If you have any question contact us!',
    },
    ru: {
      service_name: 'Координация изоляции',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Почему выбирают наши услуги?',
      get_in_touch: 'Связаться',
      question: 'Если у вас есть какие-либо вопросы, свяжитесь с нами!',
    },
    uz: {
      service_name: 'Izolyatsiyani muvofiqlashtirish',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Nima uchun bizning xizmatlarni tanlaysiz?',
      get_in_touch: "Biz bilan bog'laning",
      question: "Savollaringiz bo'lsa, biz bilan bog'laning!",
    },
  },
  {
    id: 3,
    service_id: 3,
    service_img: 'https://telegra.ph/file/a77ca35dff709b3ff3deb.jpg',
    en: {
      service_name: 'Grid Impact Studies',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Why Choose Our Services?',
      get_in_touch: 'Get in touch',
      question: 'If you have any question contact us!',
    },
    ru: {
      service_name: 'Исследования влияния сети',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Почему выбирают наши услуги?',
      get_in_touch: 'Связаться',
      question: 'Если у вас есть какие-либо вопросы, свяжитесь с нами!',
    },
    uz: {
      service_name: "Grid ta'sirini o'rganish",
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Nima uchun bizning xizmatlarni tanlaysiz?',
      get_in_touch: "Biz bilan bog'laning",
      question: "Savollaringiz bo'lsa, biz bilan bog'laning!",
    },
  },
  {
    id: 4,
    service_id: 4,
    service_img: 'https://telegra.ph/file/b8bd815db73cf1ed46a81.jpg',
    en: {
      service_name: 'Dynamic Modeling',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Why Choose Our Services?',
      get_in_touch: 'Get in touch',
      question: 'If you have any question contact us!',
    },
    ru: {
      service_name: 'Динамическое моделирование',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Почему выбирают наши услуги?',
      get_in_touch: 'Связаться',
      question: 'Если у вас есть какие-либо вопросы, свяжитесь с нами!',
    },
    uz: {
      service_name: 'Dinamik modellashtirish',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Nima uchun bizning xizmatlarni tanlaysiz?',
      get_in_touch: "Biz bilan bog'laning",
      question: "Savollaringiz bo'lsa, biz bilan bog'laning!",
    },
  },
  {
    id: 5,
    service_id: 5,
    service_img: 'https://telegra.ph/file/0587b44901cf8c142f673.jpg',
    en: {
      service_name: 'Harmonic Analysis',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Why Choose Our Services?',
      get_in_touch: 'Get in touch',
      question: 'If you have any question contact us!',
    },
    ru: {
      service_name: 'Гармонический анализ',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Почему выбирают наши услуги?',
      get_in_touch: 'Связаться',
      question: 'Если у вас есть какие-либо вопросы, свяжитесь с нами!',
    },
    uz: {
      service_name: 'Garmonik tahlil',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Nima uchun bizning xizmatlarni tanlaysiz?',
      get_in_touch: "Biz bilan bog'laning",
      question: "Savollaringiz bo'lsa, biz bilan bog'laning!",
    },
  },
  {
    id: 6,
    service_id: 6,
    service_img: 'https://telegra.ph/file/a77ca35dff709b3ff3deb.jpg',
    en: {
      service_name: 'Transfer Capacity Analysis',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Why Choose Our Services?',
      get_in_touch: 'Get in touch',
      question: 'If you have any question contact us!',
    },
    ru: {
      service_name: 'Анализ пропускной способности',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Почему выбирают наши услуги?',
      get_in_touch: 'Связаться',
      question: 'Если у вас есть какие-либо вопросы, свяжитесь с нами!',
    },
    uz: {
      service_name: 'Transfer imkoniyatlarini tahlil qilish',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Nima uchun bizning xizmatlarni tanlaysiz?',
      get_in_touch: "Biz bilan bog'laning",
      question: "Savollaringiz bo'lsa, biz bilan bog'laning!",
    },
  },
  {
    id: 7,
    service_id: 7,
    service_img: 'https://telegra.ph/file/c27ab808a3ed85296231d.jpg',
    en: {
      service_name: 'Arc Flash Studies',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Why Choose Our Services?',
      get_in_touch: 'Get in touch',
      question: 'If you have any question contact us!',
    },
    ru: {
      service_name: 'Исследования вспышки дуги',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Почему выбирают наши услуги?',
      get_in_touch: 'Связаться',
      question: 'Если у вас есть какие-либо вопросы, свяжитесь с нами!',
    },
    uz: {
      service_name: 'Arc Flash tadqiqotlari',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Nima uchun bizning xizmatlarni tanlaysiz?',
      get_in_touch: "Biz bilan bog'laning",
      question: "Savollaringiz bo'lsa, biz bilan bog'laning!",
    },
  },
  {
    id: 8,
    service_id: 8,
    service_img: 'https://telegra.ph/file/a77ca35dff709b3ff3deb.jpg',
    en: {
      service_name: 'G99 Studies',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Why Choose Our Services?',
      get_in_touch: 'Get in touch',
      question: 'If you have any question contact us!',
    },
    ru: {
      service_name: 'G99 Исследования',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Почему выбирают наши услуги?',
      get_in_touch: 'Связаться',
      question: 'Если у вас есть какие-либо вопросы, свяжитесь с нами!',
    },
    uz: {
      service_name: 'G99 tadqiqotlari',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Nima uchun bizning xizmatlarni tanlaysiz?',
      get_in_touch: "Biz bilan bog'laning",
      question: "Savollaringiz bo'lsa, biz bilan bog'laning!",
    },
  },
  {
    id: 9,
    service_id: 9,
    service_img: 'https://telegra.ph/file/ede094b3726cc453a81a3.jpg',
    en: {
      service_name: 'Relay Protection Coordination Studies',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Why Choose Our Services?',
      get_in_touch: 'Get in touch',
      question: 'If you have any question contact us!',
    },
    ru: {
      service_name: 'Координационные исследования релейной защиты',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Почему выбирают наши услуги?',
      get_in_touch: 'Связаться',
      question: 'Если у вас есть какие-либо вопросы, свяжитесь с нами!',
    },
    uz: {
      service_name: "Rele himoyasini muvofiqlashtirish bo'yicha tadqiqotlar",
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Nima uchun bizning xizmatlarni tanlaysiz?',
      get_in_touch: "Biz bilan bog'laning",
      question: "Savollaringiz bo'lsa, biz bilan bog'laning!",
    },
  },
  {
    id: 10,
    service_id: 10,
    service_img: 'https://telegra.ph/file/bc6bc08a015fff2e2d6a8.jpg',
    en: {
      service_name: 'DigSilent scripting',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Why Choose Our Services?',
      get_in_touch: 'Get in touch',
      question: 'If you have any question contact us!',
    },
    ru: {
      service_name: 'Координационные исследования релейной защиты',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Почему выбирают наши услуги?',
      get_in_touch: 'Связаться',
      question: 'Если у вас есть какие-либо вопросы, свяжитесь с нами!',
    },
    uz: {
      service_name: "Rele himoyasini muvofiqlashtirish bo'yicha tadqiqotlar",
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Nima uchun bizning xizmatlarni tanlaysiz?',
      get_in_touch: "Biz bilan bog'laning",
      question: "Savollaringiz bo'lsa, biz bilan bog'laning!",
    },
  },
  {
    id: 11,
    service_id: 11,
    service_img: 'https://telegra.ph/file/58e4af3b9c50f0f507bd1.png',
    en: {
      service_name: 'RMS Simulation',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Why Choose Our Services?',
      get_in_touch: 'Get in touch',
      question: 'If you have any question contact us!',
    },
    ru: {
      service_name: 'Координационные исследования релейной защиты',
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Почему выбирают наши услуги?',
      get_in_touch: 'Связаться',
      question: 'Если у вас есть какие-либо вопросы, свяжитесь с нами!',
    },
    uz: {
      service_name: "Rele himoyasini muvofiqlashtirish bo'yicha tadqiqotlar",
      intro_text:
        'Welcome to our specialized RMS (Root Mean Square) simulation services, where we utilize the advanced capabilities of DigSILENT PowerFactory to deliver precise and comprehensive power system analysis.',
      why_us: 'Nima uchun bizning xizmatlarni tanlaysiz?',
      get_in_touch: "Biz bilan bog'laning",
      question: "Savollaringiz bo'lsa, biz bilan bog'laning!",
    },
  },
];
