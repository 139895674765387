import { useEffect } from "react"
import { Outlet, useLocation, useNavigationType } from "react-router-dom"
const SmoothScroll = () => {
	const location = useLocation()
	const navType = useNavigationType()
	useEffect(() => {
		if (navType !== "POP") {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			})
		}
	}, [location])
	return <Outlet />
}
export default SmoothScroll
