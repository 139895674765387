import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Nav } from './nav';
import { LangMenu } from './lang-menu';
import { NavbarDrawer } from './navbar-drawer';

export const Header = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <header className="bg-white bg-opacity-80 py-2 backdrop-blur-[4px] transition-[height] duration-500 top-0 left-0 fixed w-full z-10">
      <div className="container max-w-[1280px] mx-auto px-3">
        <nav className="navigation-bar flex items-center">
          <Link to={'/'} className="no-underline mr-auto md:mr-0">
            <img
              className="navigation-bar-logo"
              src={require('assets/images/logo/logo_black.webp')}
              srcSet={require('assets/images/logo/logo_black.webp')}
              alt="Logo"
              width={180}
              height={61}
              loading="lazy"
            />
          </Link>

          <Nav />

          <ul className="navigation-links-wrapper sm:flex hidden items-center ml-auto">
            <li className="md:!flex !hidden">
              <LangMenu />
            </li>

            {/* <li className="ml-5">
              <Link
                to="https://learning.mirpower.uz/login"
                target="_blank"
                className="mx-3 text-lg cursor-pointer break-words whitespace-nowrap font-semibold hover:opacity-90"
                data-text={t('common.button.logIn')}
              >
                {t('common.button.logIn')}
              </Link>
              <Link
                to="https://learning.mirpower.uz/register"
                target="_blank"
                className="px-3 py-2 rounded-lg text-white bg-base-color mx-2 font-semibold hover:opacity-90 text-lg cursor-pointer relative break-words whitespace-nowrap no-underline"
                data-text={t('common.button.signUp')}
              >
                {t('common.button.signUp')}
              </Link>
            </li> */}
          </ul>

          <button
            className="lg:!hidden w-8 h-8 hover:opacity-70"
            onClick={handleOpen}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </nav>
      </div>

      <NavbarDrawer open={open} setOpen={setOpen} />
    </header>
  );
};
