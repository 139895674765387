import React from 'react';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Box, Drawer, IconButton } from '@mui/material';

import { LangMenu } from '../lang-menu';

export const NavbarDrawer = ({ open, setOpen }) => {
  const { t } = useTranslation();
  let location = useLocation();

  React.useEffect(() => {
    window.innerWidth > 1024 && setOpen(false);
  }, [setOpen]);

  React.useEffect(() => {
    open && setOpen(false);
  }, [location]);

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={() => setOpen(false)}
      disableEscapeKeyDown={false}
      disableScrollLock={true}
      disableRestoreFocus={true}
      BackdropProps={{
        sx: { backgroundColor: 'transparent' },
      }}
      onBackdropClick={() => setOpen(false)}
    >
      <Box
        sx={{
          width: { lg: '500px', md: '450px', sm: '500px', xs: '100%' },
          minWidth: '350px',
        }}
        role="presentation"
      >
        <div className="drawer-wrapper px-4">
          <div className="flex items-center justify-end py-3">
            <IconButton color="primary" onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </div>
          <div className="drawer-body px-3">
            <ul className="drawer-links-wrapper">
              <li className="ml-8">
                <NavLink
                  to={'/course'}
                  className={`text-gray-800 inline-block font-semibold`}
                  data-text={t('sidebar.courses')}
                >
                  {t('sidebar.courses')}
                </NavLink>
              </li>

              <li className="ml-8">
                <NavLink
                  to={'/service'}
                  className={`text-gray-800 inline-block font-semibold`}
                  data-text={t('sidebar.services')}
                >
                  {t('sidebar.services')}
                </NavLink>
              </li>

              <li className="ml-8">
                <NavLink
                  to={'/project'}
                  className={`text-gray-800 inline-block font-semibold`}
                  data-text={t('sidebar.projects')}
                >
                  {t('sidebar.projects')}
                </NavLink>
              </li>

              <li className="ml-8">
                <NavLink
                  to={'/about'}
                  className={`text-gray-800 inline-block font-semibold`}
                  data-text={t('sidebar.aboutUs')}
                >
                  {t('sidebar.aboutUs')}
                </NavLink>
              </li>
            </ul>

            <div className="drawer-language my-5 md:!hidden !flex">
              <LangMenu type="accordion" />
            </div>

            {/* <div className="drawer-login-sign-up sm:hidden flex items-center">
              <Link
                to="https://learning.mirpower.uz/login"
                target="_blank"
                className="w-1/2 text-center py-1.5 px-2.5 rounded-lg text-white bg-gradient-to-br from-base-color to-base-color-light text-lg cursor-pointer relative break-words whitespace-nowrap no-underline"
                data-text={t('common.button.logIn')}
              >
                {t('common.button.logIn')}
              </Link>
              <Link
                to="https://learning.mirpower.uz/register"
                target="_blank"
                className="w-1/2 text-center py-1.5 px-2.5 rounded-lg text-white bg-gradient-to-br from-base-color to-base-color-light ml-3 text-lg cursor-pointer relative break-words whitespace-nowrap no-underline"
                data-text={t('common.button.signUp')}
              >
                {t('common.button.signUp')}
              </Link>
            </div> */}
          </div>
        </div>
      </Box>
    </Drawer>
  );
};
