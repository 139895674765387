export const slideIn = (direction, type, delay, duration) => ({
  hidden: {
    x: direction === 'left' ? '-100%' : direction === 'right' ? '100%' : 0,
    y: direction === 'up' ? '100%' : direction === 'down' ? '100%' : 0,
  },
  show: {
    x: 0,
    y: 0,
    transition: {
      type,
      delay,
      duration,
      ease: 'easeOut',
    },
  },
});

export const staggerContainer = (staggerChildren, delayChildren) => ({
  hidden: {},
  show: {
    transition: {
      staggerChildren,
      delayChildren,
    },
  },
});

export const textVariant = (delay) => ({
  hidden: {
    y: 50,
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 1.25,
      delay,
    },
  },
});

export const textContainer = (duration = 0.15) => ({
  hidden: {
    opacity: 0,
  },
  show: (i = 1) => ({
    opacity: 1,
    transition: { staggerChildren: duration, delayChildren: i * duration },
  }),
});

export const textVariant2 = (delay = 0) => ({
  hidden: {
    opacity: 0,
    y: 20,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      ease: 'easeIn',
      delay,
    },
  },
});

export const fadeIn = (direction, type, delay, duration) => ({
  hidden: {
    x: direction === 'left' ? 100 : direction === 'right' ? -100 : 0,
    y: direction === 'up' ? 100 : direction === 'down' ? -100 : 0,
    opacity: 0,
  },
  show: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      type,
      delay,
      duration,
      ease: 'easeOut',
    },
  },
});

export const scale = (scale, delay = 0, duration = 1.5) => ({
  hidden: {
    opacity: 0,
    scale: scale,
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      duration: duration,
      delay: delay,
    },
  },
});

export const serviceCard = (delay) => ({
  hidden: {
    translateY: '30px',
    rotateY: 230,
    opacity: 0,
  },
  show: {
    translateY: '0px',
    rotateY: 360,
    opacity: 1,
    transition: { duration: 2, delay: delay, ease: 'easeOut' },
  },
});

export const projectCard = (delay) => ({
  hidden: {
    scale: 0.5,
    opacity: 0,
  },
  show: {
    scale: 1,
    opacity: 1,
    transition: { duration: 1.5, delay: delay, ease: 'easeOut' },
  },
});

export const fadeUp = (positionY, type, delay = 0, duration = 1) => ({
  hidden: {
    x: 0,
    y: positionY ? positionY : 0,
    opacity: 0,
  },
  show: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      type,
      delay,
      duration,
      ease: 'easeOut',
    },
  },
});

export const fade = ({
  direction,
  positionHiddenX = 100,
  positionHiddenY = 100,
  positionShowX = 0,
  positionShowY = 0,
  type,
  delay,
  duration,
  showOptions = {},
}) => ({
  hidden: {
    x:
      direction === 'left'
        ? positionHiddenX
        : direction === 'right'
        ? -positionHiddenX
        : 0,
    y:
      direction === 'up'
        ? positionHiddenY
        : direction === 'down'
        ? -positionHiddenY
        : 0,
    opacity: 0,
  },
  show: {
    x: positionShowX,
    y: positionShowY,
    opacity: 1,
    transition: {
      type,
      delay,
      duration,
      ease: 'easeOut',
    },
    ...showOptions,
  },
});
